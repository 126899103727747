<template>
  <b-sidebar
      id="add-new-user-sidebar"
      :visible="isModalSidebarActive"
      backdrop
      bg-variant="white"
      no-header
      right
      shadow
      sidebar-class="sidebar-lg"
      @change="(val) => $emit('update:is-modal-sidebar-active', val)"
      @hidden="resetForm"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5
            class="mb-0"
        >
          {{ $i18n.t('fotostudio.options.add_option') }}
        </h5>

        <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
          ref="refFormObserver"
          #default="{ handleSubmit }"
      >
        <!-- Form -->
        <b-form
            class="p-2"
            @submit.prevent="handleSubmit(submitForm)"
            @reset.prevent="resetForm"
        >
          <validation-provider
              #default="{ errors }"
              name="group"
              rules="required"
          >
            <b-form-group
                :label="$i18n.t('fotostudio.options.table.fields.group')"
                label-for="group"
            >
              <v-select
                  id="group"
                  v-model="form.group"
                  :clearable="false"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :label="getTranslatedName"
                  :options="groups"
                  :reduce="val => val.id"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- English value -->
          <validation-provider
              #default="{errors}"
              name="value_en"
              rules="required"
          >
            <b-form-group
                :label="$i18n.t('fotostudio.options.table.fields.value_en')"
                label-for="value_en"
            >
              <b-form-input
                  id="value_en"
                  v-model="form.value_en"
                  :state="errors.length > 0 ? false:null"
                  autofocus
                  trim
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- German value -->
          <validation-provider
              #default="{errors}"
              name="value_de"
              rules="required"
          >
            <b-form-group
                :label="$i18n.t('fotostudio.options.table.fields.value_de')"
                label-for="value_de"
            >
              <b-form-input
                  id="value_de"
                  v-model="form.value_de"
                  :state="errors.length > 0 ? false:null"
                  autofocus
                  trim
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Polish value -->
          <validation-provider
              #default="{errors}"
              name="value_pl"
              rules="required"
          >
            <b-form-group
                :label="$i18n.t('fotostudio.options.table.fields.value_pl')"
                label-for="value_pl"
            >
              <b-form-input
                  id="value_pl"
                  v-model="form.value_pl"
                  :state="errors.length > 0 ? false:null"
                  autofocus
                  trim
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="mr-2"
                type="submit"
                variant="primary"
            >
              <span>{{ $i18n.t('Add') }}</span>
            </b-button>
            <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
            >
              {{ $i18n.t('Cancel') }}
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>

import Ripple from 'vue-ripple-directive'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {required} from '@/utils/validations/validations'
import DataService from "@/views/fotostudio/services/DataService";

export default {
  emits: ['is-modal-sidebar-active', 'refresh-data'],
  directives: {
    Ripple,
  },
  model: {
    prop: 'isModalSidebarActive',
    event: 'update:is-modal-sidebar-active',
  },
  props: {
    isModalSidebarActive: {
      type: Boolean,
      required: true,
    },
    groups: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      form: {},
      blankForm: {
        group: '',
        value_en: '',
        value_de: '',
        value_pl: '',
      },
      service: new DataService(),
    }
  },
  computed: {
    getTranslatedName() {
      const lang = this.$i18n.locale;
      if (lang === "en") return "name_en"
      if (lang === "pl") return "name_pl"
      return "name_de"
    },
  },
  mounted() {
    this.resetForm()
  },
  methods: {
    resetForm() {
      this.form = JSON.parse(JSON.stringify(this.blankForm))
      this.$refs.refFormObserver.reset()
    },
    submitForm() {
      this.service.addOption(this.form).then(response => {
        this.$emit('refresh-data')
        this.resetForm()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$i18n.t('Success'),
            icon: 'EditIcon',
            variant: 'success',
            text: response.data.message
          },
        })
        this.$emit('update:is-modal-sidebar-active', false)
      }).catch(error => {
        if (error.data && error.data.errors) {
          this.$refs.refFormObserver.setErrors(error.data.errors)
        }
      })
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select';
</style>
