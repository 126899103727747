<template>
  <div>
    <add-new-option
        ref="form"
        :groups="groups"
        :is-modal-sidebar-active.sync="isModalSidebarActive"
        @refetch-data="getData"
    />
    <loading :show="loading">
      <b-card>
        <b-row>
          <b-col
              cols="12"
              md="12"
          >
            <div class="d-flex align-items-center justify-content-end mb-2">
              <b-button
                  variant="primary"
                  @click="isModalSidebarActive = true;"
              >
                <span class="text-nowrap">{{ $i18n.t('fotostudio.options.add_option') }}</span>
              </b-button>
            </div>
          </b-col>
          <b-col
              cols="12"
              md="12"
          >
            <b-row class="d-flex align-items-center justify-content-between">
              <b-col
                  md="4"
                  sm="6"
              >
                <b-form-group
                    :label="$i18n.t('fotostudio.options.table.fields.group')"
                    class="mb-0 d-flex align-items-center"
                    label-align-sm="left"
                    label-cols-sm="3"
                    label-for="groupFilter"
                    label-size="sm"
                >
                  <v-select
                      id="groupFilter"
                      v-model="selectedGroup"
                      :clearable="true"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :label="getTranslatedName"
                      :options="groups"
                      :reduce="val => val.id"
                      size="sm"
                      @input="getData"
                  />
                </b-form-group>
              </b-col>
              <table-search
                  ref="search"
                  @clear="getData"
                  @click="getData"
              />
            </b-row>
          </b-col>
        </b-row>
        <b-form
            @submit.prevent="()=>{}"
        >
          <b-table
              ref="table"
              :current-page="currentPage"
              :empty-text="$i18n.t('table.no_data')"
              :fields="fields"
              :items="items"
              :per-page="perPage"
              hover
              responsive
              show-empty
          >
            <template #cell(value_en)="data">
              <b-form-input
                  :key="data.item.id+'en'"
                  v-model="data.item.value_en"
              />
            </template>
            <template #cell(value_de)="data">
              <b-form-input
                  :key="data.item.id+'de'"
                  v-model="data.item.value_de"
              />
            </template>
            <template #cell(value_pl)="data">
              <b-form-input
                  :key="data.item.id+'pl'"
                  v-model="data.item.value_pl"
              />
            </template>
            <template #cell(delete)="data">
              <b-button
                  class="mr-2"
                  variant="danger"
                  @click="confirmDelete(data.item.id)"
              >
                <span>{{ $i18n.t('Delete') }}</span>
              </b-button>
            </template>
          </b-table>
          <b-pagination
              v-model="currentPage"
              :per-page="perPage"
              :total-rows="items.length"
              class="my-0 ml-auto"
          />
          <b-button
              class="float-md-right m-1"
              type="submit"
              variant="primary"
              @click="update()"
          >
            {{ $i18n.t('Update') }}
          </b-button>
        </b-form>
      </b-card>
    </loading>
  </div>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import DataService from "@/views/fotostudio/services/DataService";
import TableSearch from "@/views/reports/components/table/components/search.vue";
import AddNewOption from '@/views/fotostudio/pages/options/add-form.vue'

export default {
  components: {TableSearch, AddNewOption},
  data() {
    return {
      service: new DataService(),
      items: [],
      loading: false,
      perPage: 25,
      currentPage: 1,
      group: null,
      groups: [],
      isModalSidebarActive: false,
      selectedGroup: null,
    }
  },
  computed: {
    fields() {
      return [
        {
          key: this.getTranslatedName,
          label: this.$i18n.t('fotostudio.options.table.fields.group'),
          sortable: true
        },
        {
          key: 'value_en',
          label: this.$i18n.t('fotostudio.options.table.fields.value_en'),
          sortable: false
        },
        {
          key: 'value_de',
          label: this.$i18n.t('fotostudio.options.table.fields.value_de'),
          sortable: false
        },
        {
          key: 'value_pl',
          label: this.$i18n.t('fotostudio.options.table.fields.value_pl'),
          sortable: false
        },
        {
          key: 'delete',
          label: this.$i18n.t('fotostudio.options.table.fields.options'),
          sortable: false
        },
      ]
    },
    getTranslatedName() {
      const lang = this.$i18n.locale;
      if (lang === "en") return "name_en"
      if (lang === "pl") return "name_pl"
      return "name_de"
    },
  },
  mounted() {
    this.getGroups()
    this.getData()
  },
  methods: {
    getData() {
      this.loading = true
      let params = {}
      if (!this.$refs.search.isEmpty()) {
        params = {
          search: this.$refs.search.getWord(),
          lang: this.$i18n.locale,
        }
      }
      if (this.selectedGroup) {
        params = {...params, ...{group: this.selectedGroup}}
      }
      return this.service.getOptions(params).then(response => {
        this.items = response.data.data
        this.loading = false
        this.$refs.search.enable()
      }).catch(() => {
        this.loading = false
        this.$refs.search.enable()
        this.items = []
      })
    },
    update() {
      this.loading = true
      const data = this.items.map(({id, value_en, value_de, value_pl}) => ({id, value_en, value_de, value_pl}));
      this.service.updateOptions(data).then(response => {
        this.loading = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$i18n.t('Success'),
            icon: 'EditIcon',
            variant: 'success',
            text: response.data.message
          },
        })
        this.getData()
      }).catch(() => {
        this.loading = false
      })
    },
    getGroups() {
      this.service.getGroups().then(response => {
        this.groups = response.data.data
      })
    },
    confirmDelete(itemId) {
      this.$swal({
        title: this.$i18n.t('Are you sure?'),
        text: this.$i18n.t("messages.not_able_revert"),
        icon: this.$i18n.t('warning'),
        showCancelButton: true,
        confirmButtonText: this.$i18n.t('Yes, delete it'),
        cancelButtonText: this.$i18n.t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.deleteOption(itemId)
        }
      })
    },
    deleteOption(itemId) {
      this.loading = true
      this.service.deleteOption(itemId).then(response => {
        this.loading = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$i18n.t('Success'),
            icon: 'EditIcon',
            variant: 'success',
            text: response.data.message
          },
        })
        this.getData()
      }).catch(() => {
        this.loading = false
      })
    }
  }
}
</script>
<style lang="scss">
@import '~@core/scss/vue/libs/vue-select';
@import '~@core/scss/vue/libs/vue-flatpicker';
</style>
